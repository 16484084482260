import { object, string, ref } from 'yup';

import { CYRILLIC_REGEX, NUMBER_REGEX, PASSWORD_REGEX } from 'shared/constants';
import { containsPattern } from 'shared/utils/containsPattern';

export const loginSchema = object({
  email: string()
    .default('')
    .max(50, 'Максимальное количество символов - 50')
    .email('Данные введены неверно')
    .required('Введите Email'),
  password: string()
    .default('')
    .max(50, 'Максимальное количество символов - 50')
    .required('Введите пароль'),
});

export const newPasswordSchema = object({
  password: string()
    .required('Поле обязательно для заполнения')
    .min(8, 'Пароль должен быть не менее 8 символов')
    .matches(PASSWORD_REGEX, 'Пароль должен содержать хотя бы один специальный символ')
    .matches(NUMBER_REGEX, 'Пароль должен содержать хотя бы одну цифру')
    .test('is-latin', 'Разрешены только латинские символы', (value: string) =>
      containsPattern(value, CYRILLIC_REGEX),
    ),
  confirmPassword: string()
    .required('Поле обязательно для заполнения')
    .oneOf([ref('password')], 'Пароли должны совпадать'),
});

export const phoneNumberSchema = object({
  phoneNumber: string()
    .default('')
    .required('Поле обязательно для заполнения')
    .test('length', 'Поле обязательно для заполнения', (val) => val.length === 10),
});
