import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';

import { SENTRY_DSN } from '../constants';

export const useSentry = (app: App<Element>, router: Router): void => {
  if (!SENTRY_DSN) {
    return;
  }

  Sentry.init({
    app,
    dsn: SENTRY_DSN,
    sampleRate: 1,
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 50% of the performance transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [/^https:\/\/.+\.along\.pw/],
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
  });
};
